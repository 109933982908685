import React, { Fragment,useState,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleOrderForm from '../blocks/page-title/PageTitleOrderForm';
import AboutImg from '../blocks/about/AboutImg';
import AboutsUs from '../blocks/about/AboutUs';
import TeamMembers from '../blocks/team-members/TeamMembers';
import Testimonials from '../blocks/testimonials/Testimonials';
import Clients from '../blocks/clients/Clients';
import axios from 'axios';
import * as Helper from "../components/Helper";

import CheckoutForm from './CheckoutForm';
// import {loadStripe} from '@stripe/stripe-js';
// import {CardElement, Elements, useElements, useStripe} from  '@stripe/react-stripe-js';

import TablesExamples from '../blocks/ui/content/tables/children/TablesExamples';
import StripeCheckout from 'react-stripe-checkout';
import { toast } from "react-toastify";
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const OrderForm = (props) => {
    document.body.classList.add( 'page' );
    document.body.classList.add( 'title-opacity-true' );

    let oldid = props.match.params.request_id;

    let id = oldid.slice(3);

    const[orderOverview,setOrderOverview] = useState([]);
    // const stripePromise = loadStripe('pk_test_KN5zI7PnN8NqjZELJGPEKcdQ00kxVWT1r9');


    useEffect(()=>{
        getData();
},[])


function getData()
{
    axios.get(Helper.apiurl()+'get-overview/'+id)
        .then(response => {
            setOrderOverview(response.data.response.detail);
        });
}

// console.log('overview',orderOverview);

const product = {
  id: orderOverview.id,
  name: orderOverview.fullname,
  email: orderOverview.email,
  price: orderOverview.price,
  description: orderOverview.price,
};

// console.log(product);

async function handleToken(token, addresses) {
  
  const response = await axios.post(
    Helper.apiurl()+"stripe",
    { token, product }
  );
  const status  = response.data.status;
  const orderCode = response.data.response.detail.updateOrderStatus.original.response.detail.order_code;
  // console.log("Response:", response.data.response.detail.updateOrderStatus.original.response.detail.order_code);
  if (status == 200) {
    toast("Success! Check email for details", { type: "success" });

      props.history.push({
        pathname: '/track-order',
        state: orderCode
      });



  } else {
    toast("Something went wrong", { type: "error" });
  }
}

    return (
      <Fragment>
        <style>{cssstyle}</style>
        <MetaTags>
          <meta charSet="UTF-8" />
          <title>Orded Checkout Form</title>

          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <meta name="robots" content="index, follow, noodp" />
          <meta name="googlebot" content="index, follow" />
          <meta name="google" content="notranslate" />
          <meta name="format-detection" content="telephone=no" />
        </MetaTags>

        <Loading />

        <Header />

        <main id="main" className="site-main content-no-spacing">
          <div className="content">
            <div className="clearfix">
              <PageTitleOrderForm />

              <section
                id="order-payment-form"
                className="block spacer p-top-xl p-bottom-xl"
              >
                <div className="wrapper">
                  <div className="title">
                    <h6 className="text-primary text-uppercase">OverView</h6>
                  </div>

                  <div className="row gutter-width-md with-pb-md">
                    <div className="col-sm-12">
                      {/* <TablesExamples /> */}
                      <h3>Your Order Overview</h3>

                      <table className="table">
                        <tbody>
                           <tr>
                            <td>Full Name</td>
                            <td>{orderOverview.fullname}</td>
                          </tr>
                          <tr>
                            <td>Full Name</td>
                            <td>{orderOverview.fullname}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{orderOverview.email}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>{orderOverview.phone}</td>
                          </tr>

                          <tr>
                            <td>Acadmic Level</td>
                            <td>{orderOverview.academic_level}</td>
                          </tr>

                          <tr>
                            <td>Type of Paper</td>
                            <td>{orderOverview.paper_type}</td>
                          </tr>

                          <tr>
                            <td>Subject</td>
                            <td>{orderOverview.subjects}</td>
                          </tr>

                          <tr>
                            <td>Paper Instructions</td>
                            <td>{orderOverview.paper_instructions}</td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>{orderOverview.country}</td>
                          </tr>
                          <tr>
                            <td>Total Cost</td>
                            <td>$ {orderOverview.price}</td>
                          </tr>
                          <tr>
                            <td>Deadline</td>
                            <td>{orderOverview.deadline}</td>
                          </tr>

                          {orderOverview.payment_status == 1 ? (
                            <tr>
                              <td>Order ID </td>
                              <td>{orderOverview.track_id} |  <Link  to="/order-track"> Track Order </Link> </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>Pay</td>
                              <td>
                                <StripeCheckout
                                  stripeKey="pk_test_KN5zI7PnN8NqjZELJGPEKcdQ00kxVWT1r9"
                                  token={handleToken}
                                  amount={product.price * 100}
                                  name={product.name}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>

              {/* <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements> */}
            </div>
          </div>
        </main>

        <Footer />
      </Fragment>
    );
};


const cssstyle = `

.StripeCheckout
{
  background:black !important;
  background-image: None !important;
}

.StripeCheckout span
{
  background-image: None !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-family: inherit !important;
}



`
export default OrderForm;
