import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import Swal from "sweetalert2";
import * as Helper from "../Helper";

class ContactForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            values: {
                fullname: '',
                email: '',
                phone: '',
                desc: ''
            },
            successMessage: "Sender's message was sent successfully",
            warningMessage: 'Fill up the form, please!',
            errorMessage: 'Something go wrong. Try again later!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,

            track_id: ''
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.trackInput = this.trackInput.bind(this);
        
    }
    

    submitForm = async e => {
        e.preventDefault();
    
        // Simple client-side validation
        const { fullname, email, phone, desc } = this.state.values;
        if (!fullname || !email || !phone || !desc) {
            Swal.fire({
                icon: 'warning',
                title: 'Please fill out all required fields.'
            });
            return;
        }
    
        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            Swal.fire({
                icon: 'warning',
                title: 'Please enter a valid email address.'
            });
            return;
        }
    
        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }
    
        this.setState({ isSubmitting: true });
    
        // Proceed with API call if validations pass
        axios.post(Helper.apiurl() + 'getLead', this.state.values, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json; charset=UTF-8'
            },
        })
        .then(response => {
            if (response.data.status === 'success') {
                this.setState({ responseMessage: this.state.successMessage });
            }
    
            if (response.data.status === 'warning') {
                this.setState({ responseMessage: this.state.warningMessage });
            }
    
            if (response.data.status === 'error') {
                this.setState({ responseMessage: this.state.errorMessage });
            }
    
            Swal.fire({
                title: 'Submit Success! We Will Contact you Soon'
            });
    
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
                const serverErrors = error.response.data.errors;
                Swal.fire({
                    icon: 'error',
                    title: 'Submission failed!',
                    text: Object.values(serverErrors).join(', ')
                });
            } else {
                this.callAlert(this.state.errorMessage, 'error');
            }
        });
    };
    
    removeAlert = () => {
        clearTimeout( this.state.alertTimeout );
        this.setState( {
            alertTimeout: setTimeout( function() {
                    var element = document.querySelector( '#alert' );
                    element.classList.remove( 'fadeIn' );
                    element.classList.add( 'fadeOut' );
                    setTimeout( function() {
                        element.remove()
                    }, 900 )
                }, this.state.delay
            )
        } );
    };

    callAlert = ( message, type ) => {
        if ( ! document.querySelector( '#alert' ) ) {
            if ( type === 'success' ) {
                this.setState( { alertClass: 'success' } )
            }

            if ( type === 'error' ) {
                this.setState( { alertClass: 'danger' } )
            }

            if ( type === 'warning' ) {
                this.setState( { alertClass: 'warning' } )
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector( '#cf-1' );

            element.insertAdjacentHTML( 'beforeend', alert );

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState( {
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        } );


   
       

        trackInput(e)
        {
            this.setState({track_id: e.target.value});
        }
         

           
        onSubmitHandler = async e => {
            e.preventDefault();
    

            // console.log(this.state.track_id);
    
             this.props.history.push({
                pathname: '/track-order',
                state: this.state.track_id
         });

        // console.log(this.props.history.push);
        };


    render() {
        return (
            <>
            <form onSubmit={ this.submitForm } method="post" action="form.php" id="cf-1" className="contact-form">
                <div className="form-group form-group-xs">
                    <input 
                        name="fullname" 
                        type="text"
                        className="form-control form-control-lg bg-gray-light" 
                        id="cf-1-name"
                        placeholder="Full Name" 
                        value={ this.state.values.fullname }
                        onChange={ this.handleInputChange }
                        required="required" />
                </div>

                <div className="form-group form-group-xs">
                    <input 
                        name="email" 
                        type="email"
                        className="form-control form-control-lg bg-gray-light" 
                        id="cf-1-email"
                        placeholder="Email" 
                        value={ this.state.values.email }
                        onChange={ this.handleInputChange }
                        required="required" />
                </div>

                <div className="form-group form-group-xs">
                    <input 
                        name="phone" 
                        type="text"
                        className="form-control form-control-lg bg-gray-light" 
                        id="cf-1-email"
                        placeholder="Whatsapp" 
                        value={ this.state.values.phone }
                        onChange={ this.handleInputChange }
                        required="required" />
                </div>

                <div className="form-group form-group-xs">
                    <textarea 
                        name="desc" 
                        className="form-control form-control-lg bg-gray-light"
                        id="cf-1-message" 
                        placeholder="Message" 
                        value={ this.state.values.desc }
                        onChange={ this.handleInputChange }
                        required="required">
                    </textarea>
                </div>

                <div className="form-group form-group-xs mb-0">
                    <button type="submit" className="btn btn-outline-secondary">Send Message</button>
                </div>
            </form>


{/* <form onSubmit={ this.onSubmitHandler } method="post" action="form.php" id="cf-1" className="contact-form">
           

             

                <div className="form-group form-group-xs">
                    <input 
                        name="track_id" 
                        type="text"
                        className="form-control form-control-lg bg-gray-light" 
                       
                       value={this.state.track_id}
                        onChange={ this.trackInput }
                        required="required" />
                </div>

               

                <div className="form-group form-group-xs mb-0">
                    <button type="submit" className="btn btn-outline-secondary">Track</button>
                </div>
            </form> */}

            </>
        );
    }
}

export default withRouter(ContactForm);
