import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

const HeaderMenuPrimary = () => {
    return (
        <div className="menu">
            <nav className="menu-primary">
                <ul className="nav">
                   
                    <li className="nav-item">
                        <a title="Services" href={ process.env.PUBLIC_URL + "/#services" }>Services</a>
                    </li>

                    <li className="nav-item">
                        <a title="About Us" href={ process.env.PUBLIC_URL + "/#about-us" }>About Us</a>
                    </li>

                    {/* <li className="nav-item">
                        <a title="Teams" href={ process.env.PUBLIC_URL + "/#team-members" }>Team</a>
                    </li> */}

                    {/* <li className="nav-item">
                        <a title="Pricing" href={ process.env.PUBLIC_URL + "/#price-plans" }>Pricing</a>
                    </li> */}

                    {/* <li className="nav-item">
                        <a title="Testimonials" href={ process.env.PUBLIC_URL + "/#testimonials" }>Testimonials</a>
                    </li> */}

                    {/* <li className={ "nav-item " + ( window.location.pathname.includes( "/news" ) || window.location.pathname.includes( "/news-single-page" )? "current-nav-item" : "" ) }>
                        <a title="News" href={ process.env.PUBLIC_URL + "/news" }>News</a>
                    </li> */}

                    <li className="nav-item">
                        <a title="Contacts" href={ process.env.PUBLIC_URL + "/#contacts" }>Contacts</a>
                    </li>

                  
                    <li className="nav-item">
                        <Link title="Track Order" to={'/order'}>Make Order</Link>
                    </li>

                    <li className="nav-item">
                        <Link title="Track Order" to={'/order-track'}>Track Order</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default HeaderMenuPrimary;
