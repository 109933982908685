import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import News from "./pages/News";
import NewsSinglePost from "./pages/NewsSinglePost";
import SearchResults from "./pages/SearchResults";
import ServiceInside from "./pages/ServiceInside";
import page404 from "./pages/404";
import UserInterface from "./pages/UserInterface";
import OrderForm from "./pages/OrderForm";
import TrackOrder from "./pages/TrackOrder";
import OrderTrackForm from "./pages/OrderTrack";
import MakeOrder from "./pages/MakeOrder";
import Banner1 from "./pages/Banner1";
import Banner2 from "./pages/Banner2";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={Home}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/about-us"}`}
          component={AboutUs}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/news"}`}
          component={News}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/news-single-post"}`}
          component={NewsSinglePost}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/search-results"}`}
          component={SearchResults}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/service-inside"}`}
          component={ServiceInside}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/ui"}`}
          component={UserInterface}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pay-request/:request_id"}`}
          component={OrderForm}
        />

        <Route
          path={`${process.env.PUBLIC_URL + "/order-track"}`}
          component={OrderTrackForm}
        />

        <Route
          path={`${process.env.PUBLIC_URL + "/track-order"}`}
          component={TrackOrder}
          render={(props) => <TrackOrder {...props} />}
        />

        <Route
          path={`${process.env.PUBLIC_URL + "/order"}`}
          component={MakeOrder}
        />

<       Route
          path={`${process.env.PUBLIC_URL + "/banner1"}`}
          component={Banner1}
        />

<       Route
          path={`${process.env.PUBLIC_URL + "/banner2"}`}
          component={Banner2}
        />


        <Route exact component={page404} />
      </Switch>
    </Router>
  );
}

export default App;
