import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      track_id: "",
    };
    this.trackInput = this.trackInput.bind(this);
  }

  handleInputChange = (e) =>
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });

  trackInput(e) {
    this.setState({ track_id: e.target.value });
  }

  onSubmitHandler = async (e) => {
    e.preventDefault();

    this.props.history.push({
      pathname: "/track-order",
      state: this.state.track_id,
    });
  };

  render() {
    return (
      <>
        <section
          id="contacts"
          className="block bg-white-before spacer p-top-xl-2"
        >
         
          <div className="width-img spacer p-top-xl p-bottom-xl">
            <div className="wrapper">
            <Link to='/' style={{margin:'0px 80px', top:'-30px', position:'relative'}} > Back</Link>
              <div id="contact-form">
              
                <div className="title">
                  <h3 className="pb-0">Track Your Order</h3>
                </div>

                <div className="description-lg spacer p-top-lg">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>

                <div className="spacer p-top-lg">
                  <form
                    onSubmit={this.onSubmitHandler}
                    method="post"
                    action="form.php"
                    id="cf-1"
                    className="contact-form"
                  >
                    <div className="form-group form-group-xs">
                      <input
                        name="track_id"
                        type="text"
                        className="form-control form-control-lg bg-gray-light"
                        value={this.state.track_id}
                        onChange={this.trackInput}
                        required="required"
                      />
                    </div>

                    <div className="form-group form-group-xs mb-0">
                      <button
                        type="submit"
                        className="btn btn-outline-secondary"
                      >
                        Track
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(ContactForm);
