import React from 'react';

const Copyright = () => {
    return (
        <>
         <div className="copyright">
            <p> <a href="/" className="px-2"> Terms & Condition</a> |  <a href="/" className="px-2"> Refund Policy </a>  | <a href="/" className="px-2"> Contact</a> </p>
            <br/>
        </div>
        <div className="copyright text-center">
            <p>© { new Date().getFullYear() }<a href="/"> Smart Academic Help</a></p>
        </div>
        </>
    );
};

export default Copyright;
