import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import { motion } from "framer-motion";

const svgVariants = {
  hidden: {
    rotate: -100,
  },
  visible: {
    rotate: 0,
    pathLength: 1,
    transition: {
      duration: 1,
    },
  },
};

const arrowPathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

const Banner1 = () => {
  return (
    // <div className='m-0 mx-auto my-5 w-50'>
    <div>
      <style>{cssstyle}</style>

      {/* <motion.div 
initial={{x:900 , y:700, rotateY:10 , rotateX:10  }} 
animate={{ opacity: 1, rotateY:10 , rotateX:10 , x:9 , y:0 }}
transition={{delay:1, type: 'spring', stiffness: 120}}  
> <img className='w-100' src={'/assets/img/b1/bbb.png'} /></ motion.div>

 */}

      {/* <motion.div 
initial={{opacity: 0 ,}} 
animate={{ opacity: 1, }}
transition={{delay:0, ease: "easeOut", duration: 2}}  
> 

    <motion.div 
    initial={{opacity: 0 ,}} 
    animate={{ opacity: 1, }}
    transition={{delay:2, ease: "easeOut", duration: 2}}  
    > 
  
    </motion.div>

</ motion.div> */}

      <section>
        <div className="wrapper">
          <div className="framer-b1">
            {/* <img  src={'/assets/img/svg/banne.svg'} /> */}
            {/* <div className="framer-image as-b1">
                <img width="50" src={'/assets/img/b1/1.png'} />
            </div>
            <div className="framer-image as-b1">
                <img width="150" src={'/assets/img/b1/2.png'} />
            </div>

            <div className="framer-image as-b1">
                <img width="150" src={'/assets/img/b1/3.png'} />
            </div>
            <div className="framer-image as-b1">
                <img width="150" src={'/assets/img/b1/4.png'} />
            </div>
            <div className="framer-image as-b1">
                <img width="150" src={'/assets/img/b1/5.png'} />
            </div> */}

            <motion.svg
              // initial={{x:700 , y:700}}
              // animate={{ opacity: 1 , x:0 , y:0 }}
              // transition={{delay:1, type: 'spring', stiffness: 100}}
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 333 216"
              xmlSpace="preserve"
            >
              <motion.rect
                id="I"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 1.2,
                  type: "spring",
                  stiffness: 100,
                }}
                x="14.4"
                y="31.5"
                className="st3"
                width="15.2"
                height="69.2"
              />

              <motion.g
                id="IN"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.1,
                  duration: 1.1,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  id="SVGID_1_"
                  d="M93.8,71.6c-0.9-4.8-7.2-16.3-23.3-14S52.9,74.1,52.9,74.1h-0.9V58.5h-14v68.1h55.9
		C93.8,126.6,94.7,76.3,93.8,71.6z M79.1,94l-25.7,5.9c0,0-0.1,0-0.1-0.1V81.7c0-5.7,4.6-10.3,10.3-10.3l5.6-0.2
		c5.7,0,10.1,4.4,10.1,10.1L79.1,94z"
                />

                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" />
                </clipPath>

                <g style={{ clipPath: "url(#SVGID_2_)" }}>
                  <image
                    width="1080"
                    height="1090"
                    xlinkHref="/assets/img/b1/77644170.jpg"
                    transform="matrix(0.129 0 0 0.129 28 12)"
                  ></image>
                </g>

                <g class="st0">
                  {" "}
                  <path
                    class="st1"
                    d="M93.8,71.6c-0.9-4.8-7.2-16.3-23.3-14S52.9,74.1,52.9,74.1h-0.9V58.5h-14v68.1h55.9
		C93.8,126.6,94.7,76.3,93.8,71.6z M79.1,94l-25.7,5.9c0,0-0.1,0-0.1-0.1V81.7c0-5.7,4.6-10.3,10.3-10.3l5.6-0.2
		c5.7,0,10.1,4.4,10.1,10.1L79.1,94z"
                  />{" "}
                </g>
              </motion.g>

              <motion.g
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.2,
                  duration: 1.2,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <motion.path
                  id="INN"
                  className="st1"
                  d="M102.6,45.3v51.8h15.8v-26v-3.4c0,0,0.7-10.2,12.9-9.7c12.2,0.5,12.9,5,13.1,15.6v50.5h15.2V66.2 c0,0,3.4-18.3-17.9-21.5c0,0-20.6-2.7-23.3,17h-1.4l-0.2-16.3H102.6z"
                />

                <clipPath id="INN_IMG">
                  <use xlinkHref="#INN" />
                </clipPath>

                <g style={{ clipPath: "url(#INN_IMG)" }}>
                  <image
                    width="1080"
                    height="1090"
                    xlinkHref="/assets/img/b1/77644170.jpg"
                    transform="matrix(0.129 0 0 0.129 35 12)"
                  ></image>
                </g>

                <g class="st0">
                 
                  <motion.path
                    class="st1"
                    d="M102.6,45.3v51.8h15.8v-26v-3.4c0,0,0.7-10.2,12.9-9.7c12.2,0.5,12.9,5,13.1,15.6v50.5h15.2V66.2c0,0,3.4-18.3-17.9-21.5c0,0-20.6-2.7-23.3,17h-1.4l-0.2-16.3H102.6z"
                  />
                </g>
              </motion.g>

              <motion.g
                className="st0"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.3,
                  duration: 1.3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  id="INNO"
                  className="st4"
                  d="M226.6,82.4c0,16.6-13.2,26.7-29.9,26.7s-29.3-9.7-29.3-26.4s10.4-27.8,29.1-27.9 C213.2,54.7,226.6,65.8,226.6,82.4z"
                />
              </motion.g>

              <motion.g
                className="st0"
                initial={{ rotate: 90, opacity: 0, scale: 0 }}
                animate={{ rotate: 0, opacity: 1, scale: 1 }}
                transition={{
                  delay: 1.4,
                  duration: 2,
                  type: "spring",
                  stiffness: 100,
                  repeat: Infinity,
                }}
              >
                <path
                  id="INNO*"
                  className="st3"
                  d="M203.4,77.7l-4-10l-7.3,7.4l-9-9.7l7.9-7l-9.4-4.6l6.8-12.3l8.9,6.3l0.8-10.9l13.6,2.6l-2.3,10.4l10.9-2.5
			l1,14l-10.5,0.7l4.9,9.1L203.4,77.7z M199.8,65.3l4.3,10.6l10-5l-5.2-9.7l11.2-0.7l-0.8-11.1l-11.4,2.6l2.4-11.1l-10.7-2.1
			l-0.9,11.7l-9.7-6.8l-5.4,9.7l9.9,4.8l-8.4,7.4l7.1,7.7L199.8,65.3z"
                />
              </motion.g>

              <motion.g
                className="st0"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.5,
                  duration: 1.4,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <motion.polygon
                  id="INNO*V"
                  className="st4"
                  points="226.6,49.4 244.3,49.4 259.9,87.2 276.4,49.4 292.9,49.4 269.4,101.5 250.2,101.5 "
                />
              </motion.g>

              <motion.g
                id="INNO*VA"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 1,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  className="st3"
                  d="M36.1,193.8c-4.8,0-10.3-0.4-14.2-3.7c-3.5-2.9-5.3-6.9-5.3-11.8c0-4.9,2.3-8.3,6.9-10.6
				c4.4-2.2,10.8-3.1,16.9-3.8l15.9-1.5c0.2-2.9-0.4-5.3-1.8-6.9c-1.2-1.4-3-2.1-5-2.1c0,0,0,0-0.1,0c-1,0-2,0-3,0
				c-4-0.1-7.8-0.2-9.8,1.8c-1.1,1.2-1.6,3-1.5,5.5l0,0.7H18v-0.7c0-6.7,2.6-12.2,7.6-16.2c4.7-3.8,9.8-4.9,10.8-5.1
				c4.8-0.9,8.9-1,11.7-1c8.1,0,14.3,2.1,18.6,6.3c4.1,4,6.3,9.8,6.3,17.2v30.7H56.5l0-8.4l0.1-0.6
				C52.4,189.1,43.4,193.8,36.1,193.8z M49.6,152.1c2.4,0,4.5,0.9,6,2.6c1.3,1.4,2.7,4,2.1,8.5l-0.1,0.5l-17,1.6
				C26,167,18,169.6,18,178.3c0,4.5,1.6,8.1,4.8,10.7c2.8,2.3,6.8,3.3,13.3,3.3c3.7,0,8.2-1.3,12.3-3.7c4.1-2.4,7.3-5.4,8.6-8.4
				l1.8-4l-0.9,8v6.9h13.7v-29.4c0-14.3-8.3-22.1-23.5-22.1c-2.7,0-6.7,0.1-11.4,1c-0.9,0.2-5.8,1.3-10.2,4.8
				c-4.5,3.7-6.9,8.5-7.1,14.5h14.5c0-2.6,0.6-4.5,1.9-5.8c2.4-2.5,6.7-2.3,10.8-2.2C47.5,152.1,48.5,152.1,49.6,152.1
				C49.5,152.1,49.5,152.1,49.6,152.1z"
                />

                <path
                  className="st3"
                  d="M38,182.3c-1.2,0-2.3-0.3-3.2-0.9c-1.2-0.8-1.9-2-2.1-3.5c-0.3-2.4,1.3-4.2,4.1-4.5l20.5-2.6v1.2
			c0,2.7-0.9,5.1-2.7,6.8c-2.4,2.3-6.2,3.5-11.1,3.3l-4.8,0.1C38.5,182.3,38.3,182.3,38,182.3z M56,172.4l-19,2.4
			c-0.8,0.1-3.2,0.6-2.9,3c0.1,1.1,0.6,1.9,1.5,2.5c0.8,0.6,1.9,0.8,3.1,0.7l0.1,0l4.8-0.1l0,0c4.5,0.1,8-0.9,10.1-2.9
			C55.1,176.5,55.9,174.6,56,172.4z"
                />
              </motion.g>

              <motion.g
                className="st0"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.1,
                  duration: 1.1,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <motion.path
                  id="INNO*VAT"
                  className="st4"
                  d="M101.1,139.2v9.7h17.2v52l-17.4-0.3c-8.2-0.1-14.8-6.9-14.8-15.1v-23.9h-8.4v-12.7h8.1v-10L101.1,139.2z"
                />
              </motion.g>

              <motion.g
                id="INNO*VATI"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.2,
                  duration: 1.2,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <rect
                  x="124.8"
                  y="141.5"
                  className="st3"
                  width="15.2"
                  height="51.6"
                />
                <rect
                  x="124.8"
                  y="124.1"
                  className="st3"
                  width="15.2"
                  height="12"
                />
              </motion.g>

              <motion.g
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.3,
                  duration: 1.3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  id="INNOVATIO"
                  d="M208.1,167.8c0,16.6-13.2,26.7-29.9,26.7s-29.3-9.7-29.3-26.4s10.4-27.8,29.1-27.9
    C194.6,140,208.1,151.1,208.1,167.8z"
                />

                <clipPath id="INNOVATIO_Image">
                  <use xlinkHref="#INNOVATIO" style={{ overflow: "visible" }} />
                </clipPath>

                <g style={{ clipPath: "url(#INNOVATIO_Image)" }}>
                  <image
                    width="1080"
                    height="1090"
                    xlinkHref="/assets/img/b1/21069AF4.jpg"
                    transform="matrix(0.129 0 0 0.129 128 102)"
                  ></image>
                </g>

                <g>
                  <path
                    className="st5 st0"
                    d="M208.1,167.8c0,16.6-13.2,26.7-29.9,26.7s-29.3-9.7-29.3-26.4s10.4-27.8,29.1-27.9
    C194.6,140,208.1,151.1,208.1,167.8z"
                  />
                </g>
              </motion.g>

              <motion.g
                className="st0"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.4,
                  duration: 1.4,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <motion.polygon
                  id="INNO*VATIOV"
                  className="st4"
                  points="159.9,207.4 159.9,193.4 193.8,183.6 159.9,174.6 159.9,159.6 206.5,174.6 206.5,192.6 "
                />
              </motion.g>

              <motion.g
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1.5,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  id="INNOVATION"
                  d="M272.8,138.3c-0.9-4.8-7.2-16.3-23.3-14c-16.1,2.3-17.7,16.5-17.7,16.5h-0.9v-15.6h-14v68.1h55.9
	C272.8,193.3,273.7,143.1,272.8,138.3z M258.1,160.7l-25.7,5.9c0,0-0.1,0-0.1-0.1v-18.2c0-5.7,4.6-10.3,10.3-10.3l5.6-0.2
	c5.7,0,10.1,4.4,10.1,10.1L258.1,160.7z"
                />

                <clipPath id="INNOVATION_Image">
                  <use
                    xlinkHref="#INNOVATION"
                    style={{ overflow: "visible" }}
                  />
                </clipPath>

                <g style={{ clipPath: "url(#INNOVATION_Image)" }}>
                  <image
                    width="1450"
                    height="1090"
                    xlinkHref="/assets/img/b1/21069AF4.jpg"
                    transform="matrix(0.129 0 0 0.129 128 102)"
                  ></image>
                </g>

                <g>
                  <path
                    className="st5 st0"
                    d="M272.8,138.3c-0.9-4.8-7.2-16.3-23.3-14c-16.1,2.3-17.7,16.5-17.7,16.5h-0.9v-15.6h-14v68.1h55.9
	C272.8,193.3,273.7,143.1,272.8,138.3z M258.1,160.7l-25.7,5.9c0,0-0.1,0-0.1-0.1v-18.2c0-5.7,4.6-10.3,10.3-10.3l5.6-0.2
	c5.7,0,10.1,4.4,10.1,10.1L258.1,160.7z"
                  />
                </g>

                {/* <motion.path  className="st5" d="M272.8,138.3c-0.9-4.8-7.2-16.3-23.3-14c-16.1,2.3-17.7,16.5-17.7,16.5h-0.9v-15.6h-14v68.1h55.9
	C272.8,193.3,273.7,143.1,272.8,138.3z M258.1,160.7l-25.7,5.9c0,0-0.1,0-0.1-0.1v-18.2c0-5.7,4.6-10.3,10.3-10.3l5.6-0.2
	c5.7,0,10.1,4.4,10.1,10.1L258.1,160.7z"/>  */}
              </motion.g>

              {/* <motion.polyline 
initial={{ pathLength: 0 }}
animate={{ pathLength: 1 }}
transition={{delay:1.1, duration:2, repeat: Infinity  }} 
 class="st11"  points="292.5,26.5 297.5,21.5 301.5,27.5 "/> */}

              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 2.2, duration: 0.5 }}
                class="st11"
                d="M292.5,26.5l3.9-3.9c0.5-0.5,1.5-0.5,1.9,0.2l3.2,4.8"
              />

              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 1, duration: 1.5 }}
                class="st11"
                d="M292.5,115.5c0,0,34-27,19-37c-14.8-9.9-12.7-12.6-4-20c7-6,14-19,0-23c-7.8-2.2-12-10-10-13"
              />

              {/* <g>
	
		<path class="st0" d="M298.8,20.9c1.2-2.8,2.4-5.5,3.6-8.3c-0.4,0.1-0.8,0.1-1.2,0.2c2.2,2.3,4.5,4.5,6.7,6.8
			c0.7,0.7,1.7-0.4,1.1-1.1c-2.2-2.3-4.5-4.5-6.7-6.8c-0.3-0.3-1-0.3-1.2,0.2c-1.2,2.8-2.4,5.5-3.6,8.3
			C297.1,21,298.4,21.8,298.8,20.9L298.8,20.9z"/>
	
</g> */}

              {/* <motion.path initial={{x:700 , y:700}} 
animate={{ opacity: 1 , x:0 , y:0 }} transition={{delay:1}} className="st6" d="M5.5,172.5c-4.9,9.4-1.3,19.6,6.9,25.7c0.9,0.7,1.8-0.9,0.9-1.6c-7.3-5.4-10.6-14.8-6.2-23.2
			C7.5,172.4,6,171.5,5.5,172.5L5.5,172.5z"/>
	 */}

              <motion.path
                i
                initial={{ x: -100, y: -100 }}
                animate={{ opacity: 1, x: -7, y: 0, scale: 0.7 }}
                transition={{
                  delay: 1.5,
                  duration: 2.5,
                  type: "spring",
                  stiffness: 120,
                }}
                class="st6"
                d="M18.9,172.5c-4.9,9.4-1.3,19.6,6.9,25.7c0.9,0.7,1.8-0.9,0.9-1.6c-7.3-5.4-10.6-14.8-6.2-23.2
			C20.9,172.4,19.4,171.5,18.9,172.5L18.9,172.5z"
              />

              <motion.path
                i
                initial={{ x: -200, y: -200 }}
                animate={{ opacity: 1, x: -2, y: -2, scale: 0.7 }}
                transition={{
                  delay: 1.5,
                  duration: 2.5,
                  type: "spring",
                  stiffness: 120,
                }}
                class="st6"
                d="M7.6,169.7c-7.5,15.2-1.3,32,12.2,41.3c0.8,0.5,1.5-0.8,0.8-1.3C7.7,201,1.7,184.9,8.8,170.5
			C9.3,169.6,8,168.9,7.6,169.7L7.6,169.7z"
              />

              <motion.g
                initial={{ opacity: 0, x: 400, y: 100 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                transition={{
                  delay: 1.5,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 80,
                }}
              >
                <text transform="matrix(0.979 0 0 1 2.2769 115.8086)">
                  <tspan x="0" y="0" class="st8 st9">
                    continuing{" "}
                  </tspan>
                  <tspan x="0" y="6" class="st8 st9">
                    smartly
                  </tspan>
                </text>
              </motion.g>

              <motion.g
                initial={{ opacity: 0, x: 400, y: 100 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                transition={{
                  delay: 1.5,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 80,
                }}
              >
                <text transform="matrix(0.9408 0 0 1 173.9389 122.6242)">
                  <tspan x="0" y="0" class="st3 st10 st9">
                    since
                  </tspan>
                  <tspan x="0.7" y="6" class="st3 st10 st9">
                    2006
                  </tspan>
                </text>
              </motion.g>

              <motion.g
                initial={{ opacity: 0, x: 400, y: 100 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                transition={{
                  delay: 1.5,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 80,
                }}
              >
                <text transform="matrix(1.0208 0 0 1 277.1099 168.4004)">
                  <tspan x="0" y="0" class="st3 st8 st9">
                    Top quality{" "}
                  </tspan>
                  <tspan x="0" y="8" class="st3 st8 st9">
                    services and
                  </tspan>
                  <tspan x="0" y="16" class="st3 st8 st9">
                    strong team{" "}
                  </tspan>
                  <tspan x="0" y="24" class="st3 st8 st9">
                    to serve you
                  </tspan>
                </text>
              </motion.g>
            </motion.svg>
          </div>
        </div>
      </section>
    </div>
  );
};

const cssstyle = `
.framer-b1 {
    overflow: hidden;
    margin-top: -40px;
    padding-bottom: 100px;
    padding-right: 120px;
    padding-left: 160px;
//   background:lightcyan;
//   transform: scale(0.8);
//   display:inline-flex;
//   align-items: center;
}

.framer-image
{
    // width:100px;
    display:blick;
    padding:20px;
}


	.st1{fill:#f27c7cc2;}
	.st2{opacity:1;fill:#F7DA5E;}
	.st3{fill:#332634;}
	.st4{fill:#55C5D8;}
	.st5{fill:#f7da5ec4;}
	.st6{fill:#F0C915;}
	.st7{fill:#FDABAB;}
    .st8{font-family:'Poppins-Regular';}
	.st9{font-size:6px;}
	.st10{font-family:'Poppins-Regular';}

    .st11{fill:none;stroke:#55C5D8;stroke-miterlimit:10;}


`;

export default Banner1;
