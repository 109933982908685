import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import { motion } from "framer-motion";

const svgVariants = {
  hidden: {
    rotate: -100,
  },
  visible: {
    rotate: 0,
    pathLength: 1,
    transition: {
      duration: 1,
    },
  },
};

const arrowPathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 3,
      ease: "easeInOut",
    },
  },
};

const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

const Banner1 = () => {
  return (
    // <div className='m-0 mx-auto my-5 w-50'>
    <div>
      <style>{cssstyle}</style>

   

      <section>
        <div className="wrapper">
          <div className="framer-b2">
            <motion.svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1554 674"
              xmlSpace="preserve"
            >
              <motion.g
                id="T"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  class="b2_st0"
                  d="M167,271v59h38v98c0,0-4,73,72,77h72v-61l-60-1c0,0-17-1-17-22v-91l77,1v-58h-76v-45h-70v43H167"
                />
              </motion.g>

              <motion.g
                id="TH"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <path
                  class="b2_st0"
                  d="M539,321c-69,7-84,77-84,77V248h-69v311h70l1-60v-78c0-20.4,16.6-37,37-37h48.5c20.1,0,35.5,16.3,35.5,36.5
	l1,33.5c0,65,0,105,0,105h70V407C649,407,648.7,309.9,539,321z"
                />
              </motion.g>

              <motion.g
                id="THI"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <g>
                  <defs>
                    <rect
                      id="SVGID_1_"
                      x="689"
                      y="275"
                      width="67"
                      height="228"
                    />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" />
                  </clipPath>
                  <g
                    transform="matrix(1 0 0 1 3.051758e-05 0)"
                    style={{ clipPath: "url(#SVGID_2_)" }}
                  >
                    <image
                      width="626"
                      height="417"
                      xlinkHref=" /assets/img/b1/AADBD1E1.jpg"
                      transform="matrix(1.3837 0 0 1.3837 479 171)"
                    ></image>
                  </g>
                </g>
                <rect x="689" y="275" class="b2_st1" width="67" height="228" />

                <g>
                  <defs>
                    <rect
                      id="SVGID_3_"
                      x="689"
                      y="198"
                      width="67"
                      height="53"
                    />
                  </defs>
                  <clipPath id="SVGID_4_">
                    <use xlinkHref="#SVGID_3_" />
                  </clipPath>
                  <g
                    transform="matrix(1 0 0 1 3.051758e-05 0)"
                    style={{ clipPath: "url(#SVGID_4_)" }}
                  >
                    <image
                      width="626"
                      height="417"
                      xlinkHref="/assets/img/b1/AADBD1E1.jpg"
                      transform="matrix(1.3837 0 0 1.3837 479 171)"
                    ></image>
                  </g>
                </g>

                <rect x="689" y="198" class="b2_st1" width="67" height="53" />
              </motion.g>

              <motion.g
                id="THIN"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <g>
                  <defs>
                    <path
                      id="SVGID_5_"
                      d="M1055,331.7c-4.1-21.5-32.7-73.6-105.3-63.4S870,342.9,870,342.9h-4.1v-70.5h-63.4V580H1055
			C1055,580,1059,353.1,1055,331.7z M988.5,432.8l-116.2,26.6c-0.1,0-0.3-0.1-0.3-0.3v-82c0-25.7,20.8-46.5,46.5-46.5l25.5-1
			c25.7,0,45.5,19.8,45.5,45.5L988.5,432.8z"
                    />
                  </defs>
                  <clipPath id="SVGID_6_">
                    <use xlinkHref="#SVGID_5_" />
                  </clipPath>
                  <g
                    transform="matrix(1 0 0 1 3.051758e-05 0)"
                    style={{ clipPath: "url(#SVGID_6_)" }}
                  >
                    <image
                      s
                      width="626"
                      height="417"
                      xlinkHref="/assets/img/b1/AADBD1E1.jpg"
                      transform="matrix(1.3837 0 0 1.3837 479 171)"
                    ></image>
                  </g>
                </g>

                <g class="b2_st3">
                  <path
                    class="b2_st4"
                    d="M1055,331.7c-4.1-21.5-32.7-73.6-105.3-63.4S870,342.9,870,342.9h-4.1v-70.5h-63.4V580H1055
		C1055,580,1059,353.1,1055,331.7z M988.5,432.8l-116.2,26.6c-0.1,0-0.3-0.1-0.3-0.3v-82c0-25.7,20.8-46.5,46.5-46.5l25.5-1
		c25.7,0,45.5,19.8,45.5,45.5L988.5,432.8z"
                  />
                </g>
              </motion.g>

              <motion.g
                id="THINK"
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <polygon
                  class="b2_st5"
                  points="1108,193 1105,503 1176,503 1176,421 1231,421 1288,505 1370,505 1286,385 1370,274 1289,273 1231,358 
	1177,358 1177,193 "
                />
              </motion.g>

              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 1.2, duration: 1 }}
                class="b2_st6"
                d="M1422,440c0,0,72-53,52-201c-7.4-54.8-42.1-114.6-77-140"
              />

              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 1.5, duration: 1 }}
                class="b2_st6"
                d="M1396.5,124.5 1393.5,95.5 1426.5,94.5"
              />

              <motion.g
                id="STAR"
                initial={{ rotate: 90, opacity: 0, scale: 0 }}
                animate={{ rotate: 0, opacity: 1, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 100,
                  repeat: Infinity,
                }}
              >
                <path
                  class="b2_st5"
                  d="M151.2,625.7l-3-80.7l-70.6,16.8l47.1-51.9l-62.6-87.4l86.1,38.2l8.1-86.2l37.3,86.4l51.5-31.1l-23.8,48.3
			l86.1,13.6l-87.8,34.5l12.5,47.8l-37.8-24.8L151.2,625.7z M153,538.7l2.5,69.1l37-65.7l31.5,20.6l-10.3-39.6l74.8-29.4L213.9,482
			l19.2-39.1l-41.8,25.2l-31.8-73.7l-7,73.7l-75.7-33.6l54.2,75.7l-38.9,42.9L153,538.7z"
                />
              </motion.g>

              <motion.g
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 400, y: 100, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <text transform="matrix(1 0 0 1 169.2847 86.3408)">
                  <tspan x="0" y="0" class="b2_st0 b2_st7 b2_st8">
                    We don’t teach you serve what to think{" "}
                  </tspan>
                  <tspan x="0" y="42" class="b2_st0 b2_st7 b2_st8">
                    we teach you how to
                  </tspan>
                </text>
              </motion.g>

              <motion.g
                style={{ originX: 1, originY: 1, originZ: 1 }}
                initial={{ x: 500, y: 200, rotate: 200, scale: 0 }}
                animate={{ opacity: 1, x: 0, y: 0, rotate: 0, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 3,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <rect x="1102.8" y="54.6" class="b2_st0" width="246" height="69" />
                <text
                  transform="matrix(1 0 0 1 1158.4814 97.7971)"
                  class="b2_st9 b2_st7 b2_st10"
                >
                  Click Here
                </text>
              </motion.g>
            </motion.svg>
          </div>
        </div>
      </section>
    </div>
  );
};

const cssstyle = `
.framer-b2 {
    overflow: hidden;
    // margin-top: -40px;
    // padding-bottom: 100px;
    // padding-right:100px;
    // padding-left:100px;
//   background:lightcyan;
//   transform: scale(0.8);
//   display:inline-flex;
//   align-items: center;
}

.framer-image
{
    // width:100px;
    display:blick;
    padding:20px;
}



    .b2_st0{fill:#322634;}
    .b2_st1{opacity:0.65;fill:#F3D83F;}
    .b2_st2{fill:none;}
    .b2_st3{opacity:0.65;}
    .b2_st4{fill:#F3D83F;}
    .b2_st5{fill:#53C6D9;}
    .b2_st6{fill:none;stroke:#F3D83F;stroke-width:7;stroke-miterlimit:10;}
    .b2_st7{font-family:'Poppins-Regular';}
    .b2_st8{font-size:30px;}
    .b2_st9{fill:#FFFFFF;}
    .b2_st10{font-size:25px;}


`;

export default Banner1;
