import React from 'react';

const ServicesHome = () => {
    return (
        <section id="services" className="block spacer p-bottom-xl-2">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-md service-items">
                    <div className="col-sm-12 ">
                        <div className="service">
                            <div className="_hoverServices">
                            <h4 className="service-t-head">SERVICES</h4>
                            <p className="service-description"> <a href="#" className=""> 
                              BTEC Assignments  </a> 
                            | HND Assignment 
                            | MBA Assignment 
                            | Open University Assignment 
                            | CIPD Assignment 
                            | Bachelors Thesis 
                            | Master Thesis 
                            | PhD Thesis 
                            | Buy Essay 
                            | Online Quiz Services 
                            | Take My Online Class 
                            | Online Exam Help 
                            | Accounting Dissertation Topics 
                            | Psychology Dissertation Topics 
                            | Economics Dissertation Topics 
                            | Nursing Dissertation Topics 
                            | History Dissertation Topics 
                            | Business Dissertation Topics 2021 
                            | Law Dissertation Topics 
                            | HRM Dissertation Topics 
                            | Marketing Dissertation Topics 
                            | Business Management Dissertation Topics 
                            | Finance Dissertation Topics 
                            | Contract Law Dissertation Topics 
                            | Education Dissertation Topics 
                            | Literature Dissertation Topics 
                            | Sociology Dissertation Topics 
                            | Geography Dissertation Topics 
                            | Digital Marketing Dissertation Topics 
                            | Cryptocurrency Dissertation Topics 
                            | Marketing Research Paper Topics 
                            | Medical Surgical Nursing Dissertation Topics 
                            | Change Management Dissertation Topics 
                            | MA Education Dissertation Topics 
                            | Educational Psychology Dissertation Topics 
                            | Feminist Dissertation Topics 
                            | Social Media Marketing Dissertation Topics 
                            | Real Estate Dissertation Topics 
                            | Aviation Management Dissertation Topics 
                            | Criminal Law Dissertation Topics  
                            | Innovation Management Dissertation Topics 
                            | Company Law Dissertation Topics 
                            | Pediatric Nursing Dissertation Topics 
                            | Structural Engineering Dissertation Topics 
                            | Events Management Dissertation Topics 
                            | Environmental Law Dissertation Topics 
                            | Commercial Law Dissertation Topics 
                            | Education Technology Dissertation Topics 
                            | MBA Dissertation Topics 
                            | UK Assignment Help 
                            | Write My Assignment 
                            | Assignment Writing Service 
                            | Business Assignment 
                            | Law Assignment 
                            | Nursing Assignment 
                            | Thesis Writing Services 
                            | Thesis Writing Help 
                            | Thesis Helper 
                            | Essay Writing Service 
                            | Write My Essay 
                            | Essay Writers UK 
                            | College Essay Writing Service 
                            | UK Essays 
                            | Law Essay Help 
                            | Transcription Service UK 
                            | Academic Transcription 
                            | Interview Transcription 
                            | Qualitative Research Transcription 
                            | Focus Group Transcription 
                            | Research Paper Writing Service 
                            | Coursework Help 
                            | Academic Writing Help 
                            | Term Paper Writing Service</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesHome;