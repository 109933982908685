import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleOrderForm from "../blocks/page-title/PageTitleOrderForm";
import AboutImg from "../blocks/about/AboutImg";
import AboutsUs from "../blocks/about/AboutUs";
import TeamMembers from "../blocks/team-members/TeamMembers";
import Testimonials from "../blocks/testimonials/Testimonials";
import Clients from "../blocks/clients/Clients";
import axios from "axios";
import * as Helper from "../components/Helper";
import Countdown from "react-countdown";

import CheckoutForm from "./CheckoutForm";
// import {loadStripe} from '@stripe/stripe-js';
// import {CardElement, Elements, useElements, useStripe} from  '@stripe/react-stripe-js';

import TablesExamples from "../blocks/ui/content/tables/children/TablesExamples";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const Completionist = () => <span>Order Completed Check Email</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};

const TrackOrder = (props) => {
  document.body.classList.add("page");
  document.body.classList.add("title-opacity-true");

  const [orderStatus, setOrderStatus] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .post(Helper.apiurl()+ "track-order", {
        track_id: props.location.state,
      })
      .then((response) => {
        setOrderStatus(response.data.response.detail);
      });
  }

  var startTime = Date.parse(orderStatus.start_at);
  var endTime = Date.parse(orderStatus.end_at);

  return (
    <Fragment>
      <style>{cssstyle}</style>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Order Status</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header />

      <main id="main" className="site-main content-no-spacing">
        <div className="content">
          <div className="clearfix">
            {/* <PageTitleOrderForm /> */}

            <section
              id="order-payment-form"
              className="block spacer p-top-xl p-bottom-xl"
            >
              <div className="wrapper">
                <div className="title">
                  <h6 className="text-primary text-uppercase text-center">Order Status</h6>
                </div>

{ orderStatus.progress_status == 'Completed' ? 

<div className="row gutter-width-md with-pb-md">
<div className="col-sm-12">
  <h4 className="text-center">
  Your Order Has Been Completed, Check Your Email <br/> Thank you.
  </h4>

  <table className="table">
    <tbody>
      <tr>
        <td>Your Order ID</td>
        <td>{orderStatus.track_id}</td>
      </tr>

      <tr>
        <td>Full Name</td>
        <td>{orderStatus.fullname}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{orderStatus.email}</td>
      </tr>
     

      <tr>
        <td>Status</td>
        <td>{orderStatus.progress_status}</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
:
<div className="row gutter-width-md with-pb-md">
<div className="col-sm-12">
  <h4 className="text-center">
    {" "}
    {orderStatus.length != 0 && (
      <Countdown date={(startTime, endTime)}>
        <Completionist />
      </Countdown>
    )}
  </h4>

  <table className="table">
    <tbody>
      <tr>
        <td>Your Order ID</td>
        <td>{orderStatus.track_id}</td>
      </tr>

      <tr>
        <td>Full Name</td>
        <td>{orderStatus.fullname}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{orderStatus.email}</td>
      </tr>
      <tr>
        <td>Deadline</td>
        <td>{orderStatus.deadline} Days</td>
      </tr>

      <tr>
        <td>Status</td>
        <td>{orderStatus.progress_status}</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
}

              </div>
            </section>

            {/* <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements> */}
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

const cssstyle = `

.StripeCheckout
{
  background:black !important;
  background-image: None !important;
}

.StripeCheckout span
{
  background-image: None !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-family: inherit !important;
}



`;
export default TrackOrder;
