import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import axios from "axios";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import Moment from "moment";
import Dropzone, { useDropzone } from "react-dropzone";
import * as Helper from "../components/Helper";

export default function MakeOrder(props) {
  const [countries, setCountries] = useState([]);
  const [inputData, setInputData] = useState({
    fullname: "",
    email: "",
    phone: "",
    country: "",
    academic_level: "",
    paper_type: "",
    subjects: "",
    desc: "",
    deadline: "",
    start_at: "",
    end_at: "",
    price: 0,
  });
  const [attachments, setAttachments] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const academicsArray = [
    { id: 1, name: "High school" },
    { id: 2, name: "Undergraduate" },
    { id: 3, name: "Master's" },
  ];

  const paperTypeArray = [
    { id: 1, name: "Admission Essay" },
    { id: 2, name: "Article" },
    { id: 3, name: "Assignment" },
  ];

  const subjectsArray = [
    { id: 1, name: "Communication" },
    { id: 2, name: "Accounts" },
    { id: 3, name: "Education" },
  ];

  const deadlineArray = [
    { id: 1, name: "30", type: "Minutes" },
    { id: 2, name: "1", type: "Hour" },
    { id: 3, name: "2", type: "Hours" },
    { id: 4, name: "1", type: "Day" },
    { id: 5, name: "2", type: "Days" },
    { id: 6, name: "3", type: "Days" },
  ];

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 80,
    height: 80,
    padding: 3,
    boxSizing: "border-box",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  useEffect(() => {
    getCountries();
  }, []);

  function getCountries() {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/iso")
      .then((response) => {
        setCountries(response.data.data);
      });
  }

  const formChangeHandler = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...inputData, [name]: value };

    if (name === "deadline") {
      const selectedDeadline = deadlineArray.find((d) => d.id === parseInt(value));
      if (selectedDeadline) {
        updatedData = {
          ...updatedData,
          start_at: Moment().format("YYYY-MM-DD, h:mm:ss"),
          end_at: Moment().add(selectedDeadline.name, selectedDeadline.type).format(
            "YYYY-MM-DD, h:mm:ss"
          ),
        };
      }
    }

    setInputData(updatedData);
    setValidationErrors({ ...validationErrors, [name]: "" });
  };

  const handleSelectChange = (name, selectedOption) => {
    setInputData({
      ...inputData,
      [name]: selectedOption.name,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const changeHandlerForPhone = (phone) => {
    setInputData({ ...inputData, phone });
    setValidationErrors({ ...validationErrors, phone: "" });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    maxSize: 5 * 1024 * 1024, // 5 MB
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        Swal.fire({
          icon: "warning",
          title: "Invalid files (only JPEG, PNG, PDF allowed, max size 5MB)",
        });
      } else {
        setAttachments(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  const thumbs = attachments.map((file) => (
    <div style={thumb} key={file.name}>
      <div>
        <img src={file.preview} style={img} alt="attachment" />
      </div>
    </div>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    
    // Required field validations
    if (!inputData.fullname) errors.fullname = "Full name is required";
    if (!inputData.email) errors.email = "Email is required";
    if (!inputData.phone) errors.phone = "Phone number is required";
    if (!inputData.country) errors.country = "Country is required";
    if (!inputData.academic_level) errors.academic_level = "Academic level is required";
    if (!inputData.paper_type) errors.paper_type = "Paper type is required";
    if (!inputData.subjects) errors.subjects = "Subject is required";
    if (!inputData.desc) errors.desc = "Instructions are required";
    if (!inputData.deadline) errors.deadline = "Deadline is required";
    if (!inputData.start_at) errors.start_at = "Start date is required";
    if (!inputData.end_at) errors.end_at = "End date is required";
    if (attachments.length === 0) errors.attachments = "At least one attachment is required";

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      postForm();
    }
  };
 
  const postForm = () => {
    setIsLoader(true);

    let formData = new FormData();
    formData.append("fullname", inputData.fullname);
    formData.append("email", inputData.email);
    formData.append("phone", inputData.phone);
    formData.append("country", inputData.country);
    formData.append("academic_level", inputData.academic_level);
    formData.append("paper_type", inputData.paper_type);
    formData.append("subjects", inputData.subjects);
    formData.append("paper_instructions", inputData.desc);
    formData.append("deadline", inputData.deadline);
    formData.append("price", inputData.price);
    formData.append("start_at", inputData.start_at);
    formData.append("end_at", inputData.end_at);
    formData.append("user_id", 0);
    formData.append("listing_id", 0);

    attachments.forEach((file) => {
      formData.append("attachments[]", file);
    });

    axios
      .post(`${Helper.apiurl()}order-request`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        Swal.fire({
          title: "Order Request Submitted",
          text: "Thank you! We will send you a payment link soon.",
        });
        setDisableButton(false);
        setIsLoader(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text: "Please check your inputs and try again.",
        });
        setDisableButton(false);
        setIsLoader(false);
      });
  };

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Order Checkout Form</title>
      </MetaTags>

      <Loading />
      <Header />
      <div id="main-content" className="make-order-section">
        <div className="container">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="header text-center">
                    <h2>Request For Order</h2>
                  </div>

                  <div className="body">
                    <div className="form-row">
                      <div className="form-group col-6">
                        <label>Full Name</label>
                        <input
                          type="text"
                          name="fullname"
                          onChange={formChangeHandler}
                          className="form-control"
                          placeholder="Fullname"
                        />
                        {validationErrors.fullname && (
                          <div className="req-val">{validationErrors.fullname}</div>
                        )}
                      </div>
                      <div className="form-group col-6">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          onChange={formChangeHandler}
                          className="form-control"
                          placeholder="Email"
                        />
                        {validationErrors.email && (
                          <div className="req-val">{validationErrors.email}</div>
                        )}
                      </div>

                      <div className="form-group col-6">
                        <label>Phone / Whatsapp</label>
                        <PhoneInput
                          className="form-control w-100 p-0"
                          name="phone"
                          placeholder="Phone number"
                          country={"uk"}
                          onChange={changeHandlerForPhone}
                        />
                        {validationErrors.phone && (
                          <div className="req-val">{validationErrors.phone}</div>
                        )}
                      </div>

                      <div className="form-group col-6">
                        <label>Select Country</label>
                        <Select
                          placeholder="Select Country"
                          options={countries}
                          getOptionValue={(option) => option["Iso3"]}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e) => handleSelectChange("country", e)}
                          className="react-select"
                        />
                        {validationErrors.country && (
                          <div className="req-val">{validationErrors.country}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label>Select Academics</label>
                      <ul className="nav nav-pills nav-pills1 search-tabs">
                        {academicsArray.map((academic) => (
                          <li className="nav-item" key={academic.id}>
                            <label className="nav-link">
                              <input
                                type="radio"
                                value={academic.name}
                                onChange={() => handleSelectChange("academic_level", academic)}
                                name="academic_level"
                              />
                              {academic.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                      
                    </div>

                    {validationErrors.academic_level && (
                        <div className="req-val mb-3">{validationErrors.academic_level}</div>
                      )}

                    <div className="form-row">
                      <div className="form-group col-6">
                        <label>Select Paper Type</label>
                        <Select
                          placeholder="Select Paper Types"
                          options={paperTypeArray}
                          getOptionValue={(option) => option["id"]}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e) => handleSelectChange("paper_type", e)}
                          className="react-select"
                        />
                        {validationErrors.paper_type && (
                          <div className="req-val">{validationErrors.paper_type}</div>
                        )}
                      </div>

                      <div className="form-group col-6">
                        <label>Select Subjects</label>
                        <Select
                          placeholder="Select Subjects"
                          options={subjectsArray}
                          getOptionValue={(option) => option["id"]}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e) => handleSelectChange("subjects", e)}
                          className="react-select"
                        />
                        {validationErrors.subjects && (
                          <div className="req-val">{validationErrors.subjects}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-1">
                      <label>Paper Instruction</label>
                      <textarea
                        placeholder="Instruction"
                        onChange={formChangeHandler}
                        name="desc"
                        className="form-control"
                      ></textarea>
                     
                    </div>

                    {validationErrors.desc && (
                        <div className="req-val mb-4">{validationErrors.desc}</div>
                      )}

                    <div className="form-group mb-3">
                      <label>Deadline</label>
                      <ul className="nav nav-pills nav-pills1 search-tabs">
                        {deadlineArray.map((deadline) => (
                          <li className="nav-item" key={deadline.id}>
                            <label className="nav-link">
                              <input
                                type="radio"
                                value={deadline.id}
                                onChange={formChangeHandler}
                                name="deadline"
                              />
                              {deadline.name + " " + deadline.type}
                            </label>
                          </li>
                        ))}
                      </ul>
                      
                    </div>

                    {validationErrors.deadline && (
                        <div className="req-val mb-3">{validationErrors.deadline}</div>
                      )}

                    <section className="text-center">
                      <aside style={thumbsContainer}>{thumbs}</aside>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <hr />
                        <div className="m-0 m-auto pb-2">Upload Attachments</div>
                        <p className="pb-5">or drag them in (max size: 5 MB)</p>
                        {validationErrors.attachments && (
                          <div className="req-val">{validationErrors.attachments}</div>
                        )}
                      </div>
                    </section>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoader}
                    >
                      {isLoader ? "Loading..." : "Save And Generate Link"}
                      
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
